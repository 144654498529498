<script setup>
import { ref } from "vue"
import { useMemberStore } from "@/store/member"

import MemberAvatarNameWithTag from "@/components-uhas/members/MemberAvatarNameWithTag.vue"
import MemberLevelTag from "@/components-uhas/members/MemberLevelTag.vue"

const memberStore = useMemberStore()

const memberLevels = ref([])
const loading = ref(true)
const totalRecords = ref(0)

const date = ref(new Date())
const level = ref('All Level')

const optionLevel = [
	"All Level",
	"Active",
	"Trader",
	"VIP",
	"VVIP",
	"UhasX",
]

const search = (e) => {
	loading.value = true

	const page = typeof e !== 'undefined' ? (e.page || 0) + 1 : 1
	memberStore.indexMemberLevel({ page, option: { date: date.value, level: level.value } }).then((res) => {
		memberLevels.value = res.data.member_levels.data
		totalRecords.value = res.data.member_levels.total

		loading.value = false
	})
}

const clearFilter = () => {
	date.value = new Date()
	level.value = 'All Level'
	search()
}

search()
</script>

<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Members Levels</h5>
				<DataTable
					class="p-datatable-gridlines"
					dataKey="id"
					filterDisplay="row"
					:loading="loading"
					:lazy="true"
					:paginator="true"
					:rowHover="true"
					:rows="10"
					:totalRecords="totalRecords"
					:value="memberLevels"
					@page="search($event)"
				>
					<template #header>
						<div class="flex justify-content-between flex-column sm:flex-row">
							<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
							<div class="col-12 md:col-6">
								<div class="p-inputgroup">
									<Calendar v-model="date" view="month" dateFormat="mm/yy" />
									<Dropdown v-model="level" :options="optionLevel" />
									<Button icon="pi pi-search" class="p-button-warning" @click="search" />
								</div>
							</div>
						</div>
					</template>
					<template #empty>
						No records.
					</template>
					<template #loading>
						Loading. Please wait.
					</template>

					<Column header="Uhas UID">
						<template #body="{data}">
							<keep-alive>
								<router-link :to="`/members/${data.member.id}`" target="_blank">
									{{ data.member.uhas_uid }}
								</router-link>
							</keep-alive>
						</template>
					</Column>

					<Column header="Name">
						<template #body="{data}">
							<MemberAvatarNameWithTag :member="data.member" />
						</template>
					</Column>

					<Column header="Year/Month">
						<template #body="{data}">
							{{ data.year }} / {{ data.month }}
						</template>
					</Column>

					<Column header="Different">
						<template #body="{data}">
							{{ data.different }}
							<span class="ml-2" />
							<MemberLevelTag :level="data.different" />
						</template>
					</Column>

					<Column header="Updated">
						<template #body="{data}">
							{{ data.updated_at ? new Date(data.updated_at).toLocaleString() : ''}}
						</template>
					</Column>

				</DataTable>
			</div>
		</div>
	</div>
</template>
